import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import { Home, Error, AboutUs, OurStory, RSVPPage, Admin } from '../pages';

const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/home' element={<Home />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/our-story' element={<OurStory />} />
          <Route path='/rsvp' element={<RSVPPage />} />
          <Route path='/dd-admin' element={<Admin />} />
          <Route path='*' element={<Error />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
