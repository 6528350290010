import React from 'react'
import { Link } from 'react-router-dom'


const Footer = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const SubmitHandler = (e) => {
        e.preventDefault()
    }

    return (

        <footer className="wpo-site-footer">
            <div className="wpo-upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                            <div className="widget about-widget">
                                <div className="widget-title">
                                    <Link onClick={ClickHandler} className="logo" to="/"><small>Abhi</small>Shivi<span><i
                                        className="fi flaticon-dove"></i></span></Link>
                                </div>
                                <p>Welcome to the enchanting story of AbhiShivi, a radiant Indian couple whose love has blossomed into a lifelong commitment. Their wedding is a celebration of love, culture, and tradition, and we are thrilled to share their remarkable journey with you on this special wedding website.</p>
                                <ul>
                                    <li>
                                        <Link onClick={ClickHandler} to="https://www.facebook.com/profile.php?id=61552557831900" target='_blank'>
                                            <i className="ti-facebook"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={ClickHandler} to="https://twitter.com/DockDevelopers" target='_blank'>
                                            <i className="ti-twitter-alt"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={ClickHandler} to="https://www.instagram.com/dockdevelopers/" target='_blank'>
                                            <i className="ti-instagram"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={ClickHandler} to="https://www.google.com/search?q=dock+developers&sca_esv=579734912&biw=1536&bih=707&tbm=lcl&sxsrf=AM9HkKlCKCwrtTuggPrlE6g_QfYxAhhrSQ%3A1699261697759&ei=Aa1IZZ_jLZ_YseMPnpCQ-AU&oq=dock+developers&gs_lp=Eg1nd3Mtd2l6LWxvY2FsIg9kb2NrIGRldmVsb3BlcnMqAggAMgQQIxgnMg0QABgNGIAEGLEDGIMBMgcQABgNGIAEMgcQABgNGIAEMgcQABgNGIAEMgcQABgNGIAEMgcQABgNGIAEMgYQABgHGB4yBhAAGAcYHjIHEAAYDRiABEjvHVCrA1j1FHAAeACQAQCYAa8BoAGNB6oBAzAuNrgBAcgBAPgBAcICBxAAGIoFGEPCAgUQABiABMICCBAAGIoFGJECwgIHECMYsQIYJ4gGAQ&sclient=gws-wiz-local#rlfi=hd:;si:5695599091107919947,l,Cg9kb2NrIGRldmVsb3BlcnNI04XSnMa6gIAIWh0QABABIg9kb2NrIGRldmVsb3BlcnMqBggCEAAQAZIBEHNvZnR3YXJlX2NvbXBhbnmqATcQATIeEAEiGsQEeSMRupH8M8oOOOU5vJUFfW6GH4S3J9cwMhMQAiIPZG9jayBkZXZlbG9wZXJz;mv:[[28.607444377319027,77.45657680370468],[28.60708442268097,77.45616679629532]]">
                                            <i className="ti-google"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-xl-4  col-lg-4 col-md-12 col-sm-12 col-12">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    <h3>Information</h3>
                                </div>
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/about-us">About Us</Link></li>
                                    <li><Link onClick={ClickHandler} to="/our-story">Our story</Link></li>
                                    <li><Link onClick={ClickHandler} to="/rsvp">RSVP</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-xl-4  col-lg-4 col-md-12 col-sm-12 col-12">
                            <div className="widget wpo-service-link-widget">
                                <div className="widget-title">
                                    <h3>Contact Us</h3>
                                </div>
                                <div className="contact-ft">
                                    <ul>
                                        <li><i className="fi flaticon-email"></i>business@dockdevelopers.com</li>
                                        <li><i className="fi flaticon-phone-call"></i>+91 8527445257</li>
                                        <li><i className="fi flaticon-maps-and-flags"></i>Noida, Uttar Pradesh, India
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
                        <div className="widget newsletter">
                            <div className="widget-title">
                                <h3>Newsletter</h3>
                            </div>
                            <form onSubmit={SubmitHandler}>
                                <input type="text" placeholder="Email" required/>
                                <button type="submit">Subscribe</button>
                            </form>
                        </div>
                    </div> */}
                    </div>
                </div>
            </div>
            <div className="wpo-lower-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12">
                            <p className="copyright"> &copy; 2023 Design by <Link onClick={ClickHandler}
                                to="https://dockdevelopers.com">DockDevelopers</Link>. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;