import React, { useState } from 'react'
import SimpleReactValidator from 'simple-react-validator';
import SectionTitle from '../../components/SectionTitle'

import vec1 from '../../images/rsvp/flower1.png'
import vec2 from '../../images/rsvp/flower2.png'

import shape1 from '../../images/rsvp/shape1.png'
import shape2 from '../../images/rsvp/shape2.png'
import { db } from '../../firebase';
import { collection, addDoc } from "firebase/firestore"; 


const RSVP = (props) => {

    const [forms, setForms] = useState({
        name: '',
        email: '',
        isAttending: "Yes",
        guests: '0'
    });
    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));
    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        alert('We got married on 8th Decemeber, 2023. Thanks for being a part of our journey!');
        return;
        if (validator.allValid()) {
            validator.hideMessages();
            // setForms({
            //     name: '',
            //     email: '',
            //     address: '',
            //     attend: '',
            //     guest: ''
            // })
            console.log(forms);
            // await db.collection('rsvp').add(forms).then(() => {
            //     console.log('Added Successfully');
            // }).catch ((error) => {
            //     alert('Error while saving the form.')
            // })
            try {
                const docRef = await addDoc(collection(db, "rsvp"), {...forms, date: new Date().toString()});
                console.log("Document written with ID: ", docRef.id);
                setForms({
                    name: '',
                    email: '',
                    isAttending: "Yes",
                    guests: '0'
                });
                let thankYouMsg = "We will be waiting for you on the big day of AbhiShivi.";
                if (forms.isAttending === "No") thankYouMsg = '';
                alert("Thank you for sending your response. " + thankYouMsg);
              } catch (e) {
                alert("Error saving your response. Please try again after sometime.");
              }              
        } else {
            validator.showMessages();
        }
    };

    return (
        <section className={`wpo-contact-section ${props.pt}`} id="RSVP">
            <div className="container">
                <div className="wpo-contact-section-wrapper">
                    <div className="wpo-contact-form-area">
                        <SectionTitle MainTitle={'Are you attending?'} />

                        <form onSubmit={(e) => submitHandler(e)} className="contact-validation-active" >
                            <div className="form-field">
                                <input
                                    value={forms.name}
                                    type="text"
                                    name="name"
                                    onBlur={(e) => changeHandler(e)}
                                    onChange={(e) => changeHandler(e)}
                                    className="form-control"
                                    placeholder="Your Name" />
                                {validator.message('name', forms.name, 'required|alpha_space')}
                            </div>
                            <div className="form-field">
                                <input
                                    value={forms.email}
                                    type="email"
                                    name="email"
                                    onBlur={(e) => changeHandler(e)}
                                    onChange={(e) => changeHandler(e)}
                                    className="form-control"
                                    placeholder="Your Email" />
                                {validator.message('email', forms.email, 'required|email')}
                            </div>
                            <div className="radio-buttons">
                                <p>
                                    <input type="radio" id="isAttending" name="isAttending" value="Yes" defaultChecked onChange={(e) => changeHandler(e)} />
                                    <label htmlFor="isAttending">Yes, I will be there</label>
                                </p>
                                <p>
                                    <input type="radio" id="not" name="isAttending" value="No" onChange={(e) => changeHandler(e)} />
                                    <label htmlFor="not">Sorry, I can’t come</label>
                                </p>
                            </div>
                            <div className="form-field">
                                <select
                                    onBlur={(e) => changeHandler(e)}
                                    onChange={(e) => changeHandler(e)}
                                    value={forms.guests}
                                    type="text"
                                    className="form-control"
                                    name="guests">
                                    <option>Number Of Guests</option>
                                    <option>0</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                </select>
                                {validator.message('guests', forms.guests, 'required')}
                            </div>
                            {/* <div className="form-field">
                                <input
                                    value={forms.attend}
                                    type="text"
                                    name="attend"
                                    onBlur={(e) => changeHandler(e)}
                                    onChange={(e) => changeHandler(e)}
                                    className="form-control"
                                    placeholder="What Will You Be Attending" />
                                {validator.message('attend', forms.attend, 'required')}
                            </div>
                            <div className="form-field">
                                <select
                                    onBlur={(e) => changeHandler(e)}
                                    onChange={(e) => changeHandler(e)}
                                    value={forms.meal}
                                    type="text"
                                    className="form-control"
                                    name="meal">
                                    <option>Meal Preferences</option>
                                    <option>Chicken Soup</option>
                                    <option>Motton Kabab</option>
                                    <option>Chicken BBQ</option>
                                    <option>Mix Salad</option>
                                    <option>Beef Ribs </option>
                                </select>
                                {validator.message('meal', forms.meal, 'required')}
                            </div> */}
                            <div className="submit-area">
                                <button type="submit" className="theme-btn">Submit Now</button>
                            </div>
                        </form >
                    </div>
                    <div className="vector-1">
                        <img src={vec1} alt="" />
                    </div>
                    <div className="vector-2">
                        <img src={vec2} alt="" />
                    </div>
                </div>
            </div>
            <div className="shape-1">
                <img src={shape1} alt=""/>
            </div>
            <div className="shape-2">
                <img src={shape2} alt=""/>
            </div>
        </section>
    )
}
export default RSVP;
