import React, {Fragment, useEffect} from 'react';
import PageTitle from '../../components/pagetitle'
import Navbar2 from '../../components/Navbar2';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar'
import RSVP from '../../components/RSVP';
import EventSection from '../../components/EventSection/EventSection';

export const RSVPPage =() => {
    useEffect(() => {
        window.scrollTo(10, 0);
    }, []);
    return(
        <Fragment>
            <Navbar2/>
            <PageTitle pageTitle={'RSVP'} pagesub={'RSVP'}/> 
            <RSVP />
            <EventSection/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
