import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import erimg from '../../images/error-404.svg'
import Navbar2 from '../../components/Navbar2';
import { collection, getDocs } from "firebase/firestore";
import { db } from '../../firebase';

export const Admin = (props) => {
  const [rsvpData, setRsvpData] = useState(null);
  useEffect(() => {
    window.scrollTo(10, 0);
    getRSVP();
  }, []);

  const getRSVP = async () => {
    const querySnapshot = await getDocs(collection(db, "rsvp"));
    setRsvpData(querySnapshot.docs);
  }
  return (
    <>
      <Navbar2 />
      <table className="table">
        <thead>
          <tr>
            <th scope="col">S. No.</th>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Attending</th>
            <th scope="col">No. of guests</th>
            <th scope="col">Date</th>
          </tr>
        </thead>
        <tbody>
          {rsvpData && rsvpData.length > 0 && rsvpData.map((doc, index) => {
            return (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{doc.data().name}</td>
                <td>{doc.data().email}</td>
                <td>{doc.data().isAttending}</td>
                <td>{doc.data().guests}</td>
                <td>{doc.data().date}</td>
              </tr>
            );
          })}
          {rsvpData && rsvpData.length === 0 && <div>No entries yet</div>}
        </tbody>
      </table>
    </>
  )
}
