import React from "react";
import SectionTitle from "../SectionTitle";
import timg1 from '../../images/abhishivi/family/Asha Somani.jpg'
import timg2 from '../../images/abhishivi/family/Manoj Somani.jpeg'
import timg3 from '../../images/abhishivi/family/Usha Maheshwari.jpeg'
import timg4 from '../../images/abhishivi/family/Arvind Maheshwari.jpeg'

import shape1 from '../../images/team/shape1.jpg'
import shape2 from '../../images/team/shape2.jpg'


const parents = [
  {
    id:'1',
    tImg:timg1,
    name:'Asha Somani',
    title:'Brides Mother',     
 },
  {
     id:'2',
     tImg:timg2,
     name:'Manoj Somani',
     title:'Brides father',     
  },
  {
     id:'3',
     tImg:timg3,
     name:'Usha Maheshwari',
     title:'Groom’s Mother',     
  },
 {
    id:'4',
    tImg:timg4,
    name:'Arvind Maheshwari',
    title:'Groom’s Father',     
 },
]


const Parents = (props) => {

  return (
      <section className={`wpo-team-section ${props.tClass}`} id='parents'>
        <div className="container">
          <SectionTitle MainTitle={'Parents'} />
          <div className="wpo-team-wrap">
            <div className="row">
              {parents.map((brideGroom, tm) => (
                <div className="col col-lg-3 col-md-6 col-sm-12 col-12" key={tm}>
                    <div className="wpo-team-item">
                        <div className="wpo-team-img">
                            <div className="wpo-team-img-inner">
                                <img src={brideGroom.tImg} alt="" />
                            </div>
                            <div className="shape-1"><img src={shape1} alt=""/></div>
                            <div className="shape-2"><img src={shape2} alt=""/></div>
                        </div>
                        <div className="wpo-team-text">
                            <h3>{brideGroom.name}</h3>
                            <span>{brideGroom.title}</span>
                        </div>
                    </div>
                </div>
              ))}
            </div>
          </div>
        </div>
    </section>
  )
}

export default Parents;