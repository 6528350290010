import React from 'react';
import { Link } from 'react-router-dom'
import coupleImg1 from '../../images/abhishivi/p2.jpeg'
import coupleImg2 from '../../images/abhishivi/p3.jpeg'

import shape1 from '../../images/couple/shape-1.png'
import shape2 from '../../images/couple/shape-2.png'
import shape3 from '../../images/couple/shape-3.png'
import shape4 from '../../images/couple/shape-4.png'



const CoupleSection = (props) => {

    return (
        <section className={`couple-section section-padding ${props.cClass}`} id="couple">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col col-lg-11">
                        <div className="couple-area clearfix">
                            <div className="couple-item bride">
                                <div className="row align-items-center">
                                    <div className="col-lg-4">
                                        <div className="couple-img">
                                            <img src={coupleImg2} alt="" />
                                            <div className="couple-shape">
                                                <img src={shape3} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="couple-text">
                                            <h3>Shivangi Somani</h3>
                                            <p>As a bride-to-be, I am filled with joy and anticipation as I look forward to the beautiful journey of marriage, where I'll soon unite with the love of my life to create a lifetime of cherished moments and everlasting love.</p>
                                            <div className="social">
                                                <ul>
                                                    <li><Link to="https://www.instagram.com/somani_shivangi" target='_blank'><i className="ti-instagram"></i></Link></li>
                                                    <li><Link to="https://www.facebook.com/profile.php?id=100010411816771&mibextid=ZbWKwL" target='_blank'><i className="ti-facebook"></i></Link></li>
                                                    <li><Link to="https://twitter.com/Shivangisomani6" target='_blank'><i className="ti-twitter-alt"></i></Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="couple-item groom">
                                <div className="row align-items-center">
                                    <div className="col-lg-7 order-lg-1 order-2">
                                        <div className="couple-text">
                                            <h3>Abhishek Maheshwari</h3>
                                            <p>As I stand on the threshold of a new beginning, I am filled with excitement and gratitude, eagerly anticipating the wonderful journey of marriage and the opportunity to build a lifetime of love and happiness with my soulmate.</p>
                                            <div className="social">
                                                <ul>
                                                    <li><Link to="https://www.instagram.com/abhi_maheshwari/" target='_blank'><i className="ti-instagram"></i></Link></li>
                                                    <li><Link to="https://www.facebook.com/abhi.maheshwari1428" target='_blank'><i className="ti-facebook"></i></Link></li>
                                                    <li><Link to="https://x.com/Abhishe31861981" target='_blank'><i className="ti-twitter-alt"></i></Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 order-lg-2 order-1">
                                        <div className="couple-img">
                                            <img src={coupleImg1} alt="" />
                                            <div className="couple-shape">
                                                <img src={shape4} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shape-1">
                <img src={shape1} alt="" />
            </div>
            <div className="shape-2">
                <img src={shape2} alt="" />
            </div>
        </section>

    );
}



export default CoupleSection;