import React from "react";
import SectionTitle from "../SectionTitle";
import timg1 from '../../images/abhishivi/family/Lavi Somani.png'
import timg2 from '../../images/abhishivi/family/Rajeev Somani.png'
import timg3 from '../../images/abhishivi/family/Eishan Somani.jpeg'
import timg4 from '../../images/abhishivi/family/Yash Somani.jpeg'
import timg5 from '../../images/abhishivi/family/Sumit Maheshwari.jpg'
import timg6 from '../../images/abhishivi/family/Ankit Maheshwari.jpg'
import timg7 from '../../images/abhishivi/family/Deeksha Maheshwari.jpg'
import timg8 from '../../images/abhishivi/family/Arpit Maheshwari.jpeg'
import timg9 from '../../images/abhishivi/family/Manish Maheshwari.jpeg';
import timg10 from '../../images/abhishivi/family/Rashi Somani.jpg';
import timg11 from '../../images/abhishivi/family/Gaurav Maheshwari.jpeg';

import shape1 from '../../images/team/shape1.jpg'
import shape2 from '../../images/team/shape2.jpg'


const brideGrooms = [
  {
     id:'1',
     tImg:timg1,
     name:'Lavika Somani',
     title:'Brides Sister',     
  },{
   id:'11',
   tImg:timg11,
   name:'Gaurav Maheshwari',
   title:'Brides Brother-In-Law',     
},
  {
    id:'2',
    tImg:timg2,
    name:'Rajeev Somani',
    title:'Brides Brother',     
 },
 {
   id:'9',
   tImg:timg10,
   name:'Rashi Somani',
   title:'Brides Sister-In-Law',     
},
 {
    id:'3',
    tImg:timg3,
    name:'Eishan Somani',
    title:'Brides Brother',     
 },
 {
    id:'4',
    tImg:timg4,
    name:'Yash Somani',
    title:'Brides Brother',     
 },
 {
    id:'5',
    tImg:timg5,
    name:'Sumit Maheshwari',
    title:'Groom’s Brother',     
 },
 {
    id:'6',
    tImg:timg6,
    name:'Ankit Maheshwari',
    title:'Groom’s Brother',     
 },
 {
    id:'7',
    tImg:timg9,
    name:'Manish Maheshwari',
    title:'Groom’s Brother',     
 },
 {
    id:'8',
    tImg:timg7,
    name:'Deeksha Maheshwari',
    title:'Groom’s Sister',     
 },
 {
    id:'9',
    tImg:timg8,
    name:'Arpit Maheshwari',
    title:'Groom’s Brother',     
 },
]


const BrideGrooms = (props) => {

  return (
      <section className={`wpo-team-section ${props.tClass}`} id='bridal-party'>
        <div className="container">
          <SectionTitle MainTitle={'Bridesmaids & Groomsmen'} />
          <div className="wpo-team-wrap">
            <div className="row">
              {brideGrooms.map((brideGroom, tm) => (
                <div className="col col-lg-3 col-md-6 col-sm-12 col-12" key={tm}>
                    <div className="wpo-team-item">
                        <div className="wpo-team-img">
                            <div className="wpo-team-img-inner">
                                <img src={brideGroom.tImg} alt="" />
                            </div>
                            <div className="shape-1"><img src={shape1} alt=""/></div>
                            <div className="shape-2"><img src={shape2} alt=""/></div>
                        </div>
                        <div className="wpo-team-text">
                            <h3>{brideGroom.name}</h3>
                            <span>{brideGroom.title}</span>
                        </div>
                    </div>
                </div>
              ))}
            </div>
          </div>
        </div>
    </section>
  )
}

export default BrideGrooms;